// Track if we've already set up event listeners
let listenersInitialized = false;

export function addLogos() {
    const logos = [
    `/logos/artezio.png`,   // Absolute paths starting from the root
    `/logos/auriga_1.png`,
    `/logos/exness_1.png`,
    `/logos/exness_2.png`,
    `/logos/mera_1.png`,
    `/logos/rn_1.png`,
    `/logos/rn_2.png`,
    `/logos/tabby_1.png`,
    `/logos/tabby_2.webp`
    ];
    
    // Get main container
    const appContainer = document.getElementById('app');
    
    // First, remove any existing logo background containers
    const existingContainers = document.querySelectorAll('.logo-pattern-background');
    existingContainers.forEach(container => {
        container.remove();
    });
    
    // Create a new dedicated background container
    const backgroundContainer = document.createElement('div');
    backgroundContainer.classList.add('logo-pattern-background');
    backgroundContainer.id = 'logo-pattern-background';
    document.body.insertBefore(backgroundContainer, appContainer);
    
    if (!backgroundContainer) {
        return;
    }
    
    // Reduce to just 2 layers for less density
    const layers = 2;
    // Further reduced density - approximately half as many logos per layer
    const logosPerLayer = Math.max(4, Math.floor((window.innerWidth * window.innerHeight) / 80000));
    
    for (let layer = 0; layer < layers; layer++) {
        const layerContainer = document.createElement('div');
        layerContainer.classList.add('logo-layer');
        layerContainer.style.position = 'fixed';
        layerContainer.style.top = '0';
        layerContainer.style.left = '0';
        layerContainer.style.width = '100%';
        layerContainer.style.height = '100%';
        layerContainer.style.zIndex = `-${999 - layer}`;
        layerContainer.style.pointerEvents = 'none';
        
        // Different speeds for each layer - deeper layers move slower
        const speed = 0.1 + (layer * 0.1);
        layerContainer.dataset.speed = speed.toString();
        
        // Shuffle logos for this layer
        const shuffledLogos = shuffleArray([...logos]);
        let logoIndex = 0;
        
        // Create logos for this layer
        for (let i = 0; i < logosPerLayer; i++) {
            const logo = document.createElement('div');
            logo.classList.add('logo');
            const img = document.createElement('img');
            img.classList.add('logo-pattern-background-img');
            
            // Get logo from shuffled array
            img.src = shuffledLogos[logoIndex % shuffledLogos.length];
            logoIndex++;
            
            img.style.pointerEvents = 'none';
            logo.appendChild(img);
            
            // Position logos in a more evenly distributed pattern
            logo.style.position = 'absolute';
            
            // Create a grid-like distribution, but with randomness
            // Divide the screen into sections and place one logo in each section with slight randomness
            const rows = Math.ceil(Math.sqrt(logosPerLayer));
            const cols = Math.ceil(logosPerLayer / rows);
            
            const rowIndex = Math.floor(i / cols);
            const colIndex = i % cols;
            
            // Calculate base position (grid cell)
            const baseTop = (100 / rows) * rowIndex;
            const baseLeft = (100 / cols) * colIndex;
            
            // Add less randomness within the cell (20% of cell size)
            // This helps prevent overlaps by keeping logos more centered in their cells
            const randTop = (Math.random() * 20) - 10;
            const randLeft = (Math.random() * 20) - 10;
            
            // Set position with added randomness
            logo.style.top = `${baseTop + randTop}%`;
            logo.style.left = `${baseLeft + randLeft}%`;
            
            // Random rotation for visual interest
            logo.style.transform = `rotate(${Math.random() * 60 - 30}deg)`;
            
            // Increase spacing between logos
            const cellPadding = 15; // smaller percentage of cell to use for logo
            logo.style.width = `${cellPadding}%`;
            logo.style.height = `${cellPadding}%`;
            
            // Scale logos differently based on layer (perspective effect)
            const scale = 1 - (layer * 0.15);
            img.style.maxWidth = `${7 * scale}rem`;
            img.style.maxHeight = '100%';
            img.style.objectFit = 'contain';
            
            layerContainer.appendChild(logo);
        }
        
        backgroundContainer.appendChild(layerContainer);
    }
    
    // Only set up event listeners once
    if (!listenersInitialized) {
        // Add scroll event listener for parallax effect with performance optimization
        let ticking = false;
        let lastScrollY = window.scrollY;
        
        window.addEventListener('scroll', () => {
            lastScrollY = window.scrollY;
            
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    document.querySelectorAll('.logo-layer').forEach(layer => {
                        const speed = parseFloat(layer.dataset.speed);
                        layer.style.transform = `translateY(${lastScrollY * speed}px)`;
                    });
                    ticking = false;
                });
                
                ticking = true;
            }
        });
        
        // Set up the resize handler only once
        window.addEventListener('resize', () => {
            clearTimeout(window.resizeTimer);
            window.resizeTimer = setTimeout(() => {
                addLogos();
            }, 200);
        });
        
        // Set the flag to indicate we've initialized event listeners
        listenersInitialized = true;
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
