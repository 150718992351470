<template>
  <div id="app">
    <header>
      <h1><router-link to="/">Ivan Rozhkov</router-link></h1>
      
      <!-- Hamburger Icon -->
      <div class="hamburger" @click="toggleMenu">
        <div class="bar" v-if="!isMenuOpen"></div>
        <div class="bar" v-if="!isMenuOpen"></div>
        <div class="bar" v-if="!isMenuOpen"></div>
        <div v-if="isMenuOpen">✖</div>
      </div>

      <!-- Navigation Links -->
      <nav :class="{ open: isMenuOpen }">
        <router-link @click="closeMenu" to="/">Blog</router-link>
        <router-link @click="closeMenu" to="/about">About Me</router-link>
        <router-link @click="closeMenu" to="/experience">Professional Experience</router-link>
        <router-link @click="closeMenu" to="/contact">Contact</router-link>
      </nav>
    </header>

    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from './components/Footer.vue';
import { addLogos } from './utils';

export default {
  name: 'App',
  components: {
    FooterComponent
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  mounted() {
    addLogos();
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    }
  }
};
</script>

<style>
body {
  font-family: 'Montserrat', sans-serif; /* Use Google Fonts */
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background: #f4f4f4;
}

header {
  position: fixed;
  top: 0; /* Position the header at the top of the viewport */
  background: linear-gradient(45deg, #333, #444);
  color: #fff;
  padding: 1rem 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  left: 0;
  width: 100%;
  height: 5rem; 
  z-index: 1000;
}

header h1 {
  position: relative;
  margin-top: -0.5rem;
}

header h1 a {
  color: #fff;
  margin: 0 2rem;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem;
  transition: background 0.3s ease, color 0.3s ease;
}

header nav a {
  color: #fff;
  margin: 0 1rem;
  font-weight: 500;
  padding: 0rem;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease; 
}

header nav a:hover {
  background: #555;
  color: #f4f4f4;
}

header nav a.router-link-exact-active {
  font-weight: bold;
}

/* Hamburger Icon Styles */
.hamburger {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2rem;
  width: 2rem;
}

.hamburger .bar {
  width: 100%;
  height: 4px;
  background-color: #fff;
  margin: 3px 0;
}

/* Mobile Navigation Menu */
nav {
  display: none;
  flex-direction: column;
  align-items: center;
  background: #333;
  position: absolute;
  top: 4.5rem;
  right: 0;
  width: 30%;
  padding: 0.5rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: max-height 0.3s ease;
  max-height: 0; /* Initially hidden */
  overflow: hidden;
}

nav.open {
  display: flex;
  max-height: 300px;
}



/* Desktop Specific Styles */
@media (min-width: 769px) {
  .hamburger {
    display: none;
  }

  nav {
    display: flex;
    flex-direction: row;
    position: static;
    height: auto;
    width: auto;
    justify-content: center;
    background: none;
  }

  nav a {
    margin: 0 1rem;
    font-size: 1rem;
  }
}


#app {
  padding-top: 4rem;
  padding-bottom: 3rem;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

a {
  color: #FF6347; /* Tomato as accent color */
  transition: color 0.3s ease;
}

a:hover {
  color: #FF4500; /* Darker accent */
}

.logo-pattern-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
  overflow: hidden;
}

.logo-pattern-background .logo {
  pointer-events: none; 
  position: absolute;
  z-index: -999; 
}

@keyframes fadeInLogo {
  from { opacity: 0; }
  to { opacity: 0.3; }
}

.logo-pattern-background .logo-pattern-background-img {
  opacity: 0.3;  /* Increased opacity since we have much fewer logos */
  max-width: 7.5rem;
  height: auto;
  animation: fadeInLogo 0.5s ease;
  transition: transform 0.2s ease-out;
  filter: grayscale(15%); /* Further reduced grayscale for better visibility */
}

.logo-layer {
  transition: transform 0.1s ease-out;
}

html, body {
  overflow-x: hidden; 
  margin: 0;
  padding: 0;
}

</style>