<template>
  <section id="salary-calculator">
    <h2>Salary Calculator</h2>
    <div class="calculator-container">
      <div class="input-group">
        <label for="net">Expected NET (monthly)</label>
        <input
            type="number"
            id="net"
            v-model="net"
            @input="calculateGross"
            placeholder="Enter NET amount"
        >
      </div>

      <div class="input-group">
        <label for="gross">Required GROSS (monthly)</label>
        <input
            type="number"
            id="gross"
            v-model="gross"
            @input="calculateNet"
            placeholder="Enter GROSS amount"
        >
      </div>
    </div>
  </section>
</template>

<script>
import {config} from '@/config.js';
export default {
  name: 'SalaryCalculator',
  data() {
    return {
      net: '',
      gross: '',
      calculationTimeout: null
    };
  },
  methods: {
    calculateGross() {
      clearTimeout(this.calculationTimeout);
      if (!this.net) {
        this.gross = '';
        return;
      }

      this.calculationTimeout = setTimeout(async () => {
        try {
          const response = await fetch(`${config.API_BASE_URL}/calculate-gross`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ net: parseFloat(this.net) })
          });

          if (response.ok) {
            const data = await response.json();
            this.gross = data.gross;
          }
        } catch (error) {
          console.error('Error calculating gross:', error);
        }
      }, 500);
    },

    calculateNet() {
      clearTimeout(this.calculationTimeout);
      if (!this.gross) {
        this.net = '';
        return;
      }

      this.calculationTimeout = setTimeout(async () => {
        try {
          const response = await fetch(`${config.API_BASE_URL}/calculate-net`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ gross: parseFloat(this.gross) })
          });

          if (response.ok) {
            const data = await response.json();
            this.net = data.net;
          }
        } catch (error) {
          console.error('Error calculating net:', error);
        }
      }, 500);
    }
  }
};
</script>

<style scoped>
#salary-calculator {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.calculator-container {
  max-width: 400px;
  margin: 0 auto;
}

.input-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #333;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>