import { createRouter, createWebHistory } from 'vue-router';
import BlogList from '../components/BlogList.vue';
import BlogPost from '../components/BlogPost.vue';
import AboutMe from '../components/AboutMe.vue';
import ProfessionalExperience from '../components/ProfessionalExperience.vue';
import ContactForm from '../components/ContactForm.vue';
import SalaryCalculator from '../components/SalaryCalculator.vue';


const routes = [
    {
        path: '/',
        name: 'Blog',
        component: BlogList
    },
    {
        path: '/blog/:id',
        name: 'BlogPost',
        component: BlogPost
    },
    {
        path: '/about',
        name: 'AboutMe',
        component: AboutMe
    },
    {
        path: '/experience',
        name: 'ProfessionalExperience',
        component: ProfessionalExperience
    },
    {
        path: '/contact',
        name: 'ContactForm',
        component: ContactForm
    },
    {
        path: '/salary-calculator',
        name: 'SalaryCalculator',
        component: SalaryCalculator
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Ivan Rozhkov';
    next();
});

export default router;
